<template>
  <Head>
    <title>Контакты {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Контактные данные жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА - адрес объекта и офиса продаж, контактный телефон и e-mail."
    />
    <meta property="og:title" :content="'Контакты ' + $titleEnd" />
    <meta
      property="og:description"
      content="Контактные данные жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА - адрес объекта и офиса продаж, контактный телефон и e-mail."
    />
  </Head>
  <main class="main contacts-page">
    <div class="container">
      <div ref="content" class="content">
        <Breadcrumbs
          :links="[{ name: 'Home', text: 'Главная' }, { text: 'Контакты' }]"
        />
        <h1 class="page-title h2">Контакты</h1>
        <div class="address">
          <div class="h5 address__title">Адрес объекта</div>
          <div class="h3 address__text">
            {{ contacts[1].addr_location }}
          </div>
        </div>
        <div class="address">
          <div class="h5 address__title">Адрес офиса продаж</div>
          <div class="h3 address__text">
            {{ contacts[0].addr_location }}
          </div>
        </div>
        <div class="map">
          <yandex-map
            :coords="map.center"
            :controls="[]"
            :behaviors="behaviors"
            :zoom="16"
          >
            <ymap-marker
              marker-id="office"
              :options="{ cursor: 'auto' }"
              :coords="[
                contacts[0].addr_lat,
                contacts[0].addr_lon,
              ]"
              :icon="{
                layout: 'default#image',
                imageHref: require('@i/marker_office.png'),
                imageSize: [87, 93],
                imageOffset: [-28, -61],
              }"
            />
            <ymap-marker
              marker-id="zk"
              :options="{ cursor: 'auto' }"
              :coords="[
                contacts[1].addr_lat,
                contacts[1].addr_lon,
              ]"
              :icon="{
                layout: 'default#image',
                imageHref: require('@i/marker.png'),
                imageSize: [56, 61],
                imageOffset: [-28, -61],
              }"
            />
          </yandex-map>
          <div class="map__shade" />
        </div>
        <div class="conts row justify-content-lg-between flex-wrap">
          <div v-if="contacts[0]?.addr_phone" class="conts__item col-sm-auto">
            <div class="h6 conts__title">
              <a
                :href="'tel:' + $filters.phoneFormat(contacts[0].addr_phone)"
                class="comagic_phone"
              >
                {{ contacts[0].addr_phone }}
              </a>
            </div>
            <p class="conts__text">Контактный телефон</p>
          </div>
          <!-- <div class="conts__item col-sm-auto">
            <div class="h6 conts__title">
              <a :href="'mailto:' + $email">{{ $email }}</a>
            </div>
            <p class="conts__text">Контактный e-mail</p>
          </div> -->
        </div>
        <div class="time">
          <div class="h6 time__title">Время работы</div>
          <div
            v-if="contacts[0]?.addr_work_time"
            class="time__text"
            v-html="contacts[0].addr_work_time"
          />
        </div>
        <div class="contacts-page__mob time">
          <div class="h6 conts__title"><a href="tel:+74954322393">+7 (495) 432-23-93</a></div>
          <div class="time__text">Агентствам недвижимости по вопросам сотрудничества</div>
        </div>
        <div class="time btns row no-gutters">
          <div class="btns__item col-sm-auto">
            <button
              class="btn btn-default btn-small"
              data-popup-with-chats
              data-popup-with-chats-title="Заказать обратный звонок"
            >
              Заказать обратный звонок
            </button>
          </div>
          <div
            v-if="contacts[0]?.pdf_drive_parking?.source"
            class="btns__item col-sm-auto"
          >
            <a
              class="btn btn-transparent btn-small"
              :href="contacts[0].pdf_drive_parking.source"
              target="_blank"
            >
              Схема проезда
            </a>
          </div>
        </div>

        <div class="contacts-page__desk">
          <div class="h6 conts__title"><a href="tel:+74954322393">+7 (495) 432-23-93</a></div>
          <div class="time__text">Агентствам недвижимости по вопросам сотрудничества</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { Head } from "@vueuse/head";

export default {
  components: {
    Head,
    Breadcrumbs,
    yandexMap,
    ymapMarker,
  },
  inject: ["mainStore"],
  data() {
    return {
      map: {
        center: [55.833031, 37.593605],
      },
      behaviors: is_touch() ? ["multiTouch"] : ["default"],
    };
  },
  computed: {
    contacts() {
      return this.mainStore.state.data?.addresses.addr_list;
    },
  },
  async mounted() {
    if (!is_touch()) {
      gsap.from(this.$refs.content.children, {
        autoAlpha: 0,
        y: 20,
        stagger: { each: 0.1 },
      });
    }
  },
};
</script>

<style scoped>
.main {
  margin-bottom: 70px;
}

.content {
  max-width: 480px;
  /* position: relative;
		z-index: 10; */
}

.content > *:not(.map) {
  position: relative;
  z-index: 4;
}

.address {
  margin-bottom: 40px;
}

.address__title {
  margin: 0 0 17px;
}

.address__text {
  margin: 0;
  line-height: 0.9;
}

.conts {
  margin-bottom: 20px;
}

.conts__item {
  margin-bottom: 20px;
}

.conts__title {
  margin: 0;
}

.conts__title a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .conts__title a:hover {
  color: #205640;
}

.conts__text {
  margin: 5px 0 0;
}

.time {
  margin-bottom: 25px;
}

.time__title {
  margin: 0;
}

.time__text {
  margin: 10px 0 0;
}

.time__text :deep(h6),
.time__text :deep(.h6) {
  color: inherit;
  font-size: inherit;
  font-weight: 500;
  text-transform: none;
  margin: 10px 0;
}

.btns__item {
  margin: 0 3px 0 0;
}

.map {
  position: absolute;
  top: 76px;
  right: 0;
  width: 65%;
  height: 740px;
}

.map::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 27%;
  height: 100%;
  background: linear-gradient(-90deg, rgb(255 255 255 / 0) 0%, #fff 100%);
  pointer-events: none;
}

.map::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 27%;
  background: linear-gradient(180deg, rgb(255 255 255 / 0) 0%, #fff 100%);
  pointer-events: none;
}

.map__shade {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.map__shade::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 27%;
  height: 100%;
  background: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, #fff 100%);
  pointer-events: none;
}

.map__shade::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 27%;
  background: linear-gradient(0deg, rgb(255 255 255 / 0) 0%, #fff 100%);
  pointer-events: none;
}

.map :deep([class*="ymaps-2"][class*="-ground-pane"]) {
  filter: grayscale(100%);
}

.contacts-page__mob {
  display: none;
}
@media (max-width: 1279px) {
  .map {
    width: 60%;
  }
}
@media (max-width: 991px) {
  .content {
    max-width: none;
  }

  .address {
    margin-bottom: 20px;
  }

  .map {
    position: relative;
    top: 40px;
    width: auto;
    height: 450px;
    margin: -50px 0 20px;
  }

  .map__shade {
    display: block;
  }

  .map::before {
    width: 40px;
  }

  .map::after {
    height: 160px;
  }

  .map__shade::before {
    width: 40px;
  }

  .map__shade::after {
    height: 160px;
  }

  .conts {
    margin-bottom: 5px;
  }

  .time {
    margin-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .map {
    height: 400px;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 40px;
  }

  .map::before {
    display: none;
  }

  .map__shade::before {
    display: none;
  }

  .conts {
    margin-bottom: 0;
  }

  .btns {
    margin-bottom: -10px;
    text-align: center;
  }

  .btns__item {
    margin-bottom: 10px;
  }

  .contacts-page__desk {
    display: none;
  }
  .contacts-page__mob {
    display: block;
  }
}
</style>
